<template>
  <div>
    <admin-add-new
      :is-add-new-admin-sidebar-active.sync="isAddNewAdminSidebarActive"
      :role-options="roleOptions"
      @refetch-data="refetchData"
      v-on:success-toast="successToast"
    />
  <b-card>

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
          />
          <b-button
            variant="primary"
            @click="isAddNewAdminSidebarActive = true"
            class="ml-1"
          >
            <span class="text-nowrap">Ajouter administrateur</span>
          </b-button>
        </div>

      </b-form-group>

    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="allAdmins"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      styleClass="vgt-table striped condensed"
      id="admins-table">
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'full_name'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
            :variant="avatarColors(flattenUUIDtoBase(props.row.id))"

          />
          <span class="text-nowrap">{{ props.row.full_name }}</span>
        </span>

        <!-- Column: type -->
        <span v-else-if="props.column.field === 'role'">
          <b-badge :variant="getRoleVariant(props.row.role)">
            {{ getRoleLabel(props.row.role) }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item :to="{ name: 'administrators-edit', params: { id: props.row.id }}">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Modifier</span>
              </b-dropdown-item>
              <!--
              <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Supprimer</span>
              </b-dropdown-item>
              -->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Indiquer 1 à
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> des {{ props.total }} données </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <template #code>
    </template>
  </b-card>
  </div>
</template>

<script>
import {
  BCard, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import AdminAddNew from './AdminAddNew.vue'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import roleConfig from '@core/data/roleConfig.js'

export default {
  components: {
    AdminAddNew,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ToastificationContent,
  },
  setup() {
    const roleOptions = roleConfig.roles.filter(role => role.value !== 'agency_admin' && role.value !== 'agency_agent');
    const allAdmins = ref([])

    let isAddNewAdminSidebarActive = false;

    const fetchAdmins = () => {
      store.dispatch('app-admins/fetchAdmins', {}).then(response => {
        allAdmins.value = response.data;
      })
    }

    const refetchData = () => {
      fetchAdmins()
    }

    fetchAdmins()

    return {
      roleOptions,
      allAdmins,
      isAddNewAdminSidebarActive,
      refetchData,
      roleConfig
    }
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'Nom',
          field: 'full_name',
        },
        {
          label: 'Courriel',
          field: 'email',
        },
        {
          label: 'Rôle',
          field: 'role',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      role: [{
        1: 'Coordinator',
        2: 'Administrator',
      },
      {
        1: 'light-primary',
        2: 'light-success',
      }],
    }
  },
  computed: {
    flattenUUIDtoBase() {
      const UUID_BASE = 16;
      const BASE = 2;
      return character => Math.round(parseInt(character.slice(-1),UUID_BASE)/BASE)
    }
    ,
    avatarColors() {

      const avatarColors = {
        "1":"primary",
        "2":"dark",
        "3":"info",
        "4":"success",
        "5":"danger",
        "6":"warning",
        "7":"info",
        "8":"secondary",
      }
      return character => avatarColors[character]
    }
  },
  methods: {
    getRoleVariant(role) {
      const roleColor = {
        organization_coordinator  : 'light-primary',
        organization_admin        : 'light-warning',
      }
      return roleColor[role]
    },
    getRoleLabel(role) {
      return roleConfig.roles.find(x => x.value === role).label
    },
    successToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Admin sauvegardé',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    }
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
